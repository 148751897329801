import { PageGrid, PageGridItem, Breadcrumbs } from "@whitespace/components";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { useContentTypes } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import {
  getFrontPage,
  getAncestors,
  getPage,
} from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./PageBreadcrumbs.module.css";

PageBreadcrumbs.propTypes = {
  hideCurrent: PropTypes.bool,
  hideRoot: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function PageBreadcrumbs({
  hideCurrent,
  hideRoot,
  items: customItems,
  className,
  styles = defaultStyles,
  ...restProps
}) {
  const allPages = usePages();
  let items = [getFrontPage(allPages)];
  const contentTypes = useContentTypes();
  if (customItems) {
    items.push(...customItems);
  } else {
    const pageContext = usePageContext();
    const { contentNode: { contentType: { node: contentType } = {} } = {} } =
      pageContext;
    const pageContentTypeName = contentType?.name;
    switch (pageContentTypeName) {
      case "page":
        {
          const {
            contentNode: { id: pageId, isFrontPage },
          } = pageContext;
          if (!isFrontPage) {
            items.push(
              ...getAncestors(allPages, pageId),
              getPage(allPages, pageId)
            );
          }
        }
        break;
      default: {
        let postType = contentTypes.find(
          (contentType) => contentType.name === pageContentTypeName
        );
        if (postType) {
          let { labels: { menuName: label } = {}, uri: url } = postType;
          items.push({ label, url });
        }
        items.push(pageContext.contentNode);
      }
    }

    if (hideRoot) {
      items.shift();
    }

    if (hideCurrent) {
      items.pop();
    }
  }
  if (!items || items.length === 0) {
    return null;
  }

  items = items.filter(Boolean).map(({ title, label, uri: url }) => ({
    label: label || title,
    url,
  }));

  const getUrl = () => {
    let url = items[2]?.url;
    url = url?.substring(0, url.length - 1);
    return url?.slice(0, url?.lastIndexOf("/"));
  };

  for (const item of items) {
    if (!item?.url && items.indexOf(item) === 1) {
      item.url = getUrl();
    }
  }

  return (
    <div className={clsx(styles.component)}>
      <Breadcrumbs
        hideDescription={true}
        items={items}
        className={clsx(styles.inner)}
      />
    </div>
  );
}
