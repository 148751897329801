import { H, Section } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Link } from "@whitespace/components";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./AlphabeticalPostsModule.module.css";

AlphabeticalPostsModule.propTypes = {
  className: PropTypes.string,
  module: PropTypes.shape({
    modPostsDataDisplay: PropTypes.shape({ theme: PropTypes.string }),
  }),
  normalizedItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
    })
  ),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
};

export default function AlphabeticalPostsModule({
  className,
  module,
  normalizedItems,
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const {
    modPostsDataDisplay: { theme },
  } = module;
  const { t } = useTranslation();

  const groupedTerms = normalizedItems.reduce((acc, term) => {
    const letter = term.title[0].toUpperCase();
    if (!acc[letter]) {
      acc[letter] = { letter, terms: [term] };
    } else {
      acc[letter].terms.push(term);
    }
    return acc;
  }, {});

  const sections = Object.values(groupedTerms).sort((a, b) =>
    a.letter.localeCompare(b.letter, "sv")
  );

  return (
    <ModuleWrapper
      {...restProps}
      className={clsx(styles.component, theme, className)}
    >
      <Section>
        <H className={utilities.visuallyHidden}>{t("Pages")}</H>
        <div className={styles.sections}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.inner}>
            {sections.map((section, index) => (
              <section key={index} className={styles.section}>
                <H className={styles.letter}>
                  <span className={utilities.visuallyHidden}>
                    {t("Pages that starts with")}
                  </span>{" "}
                  {section.letter}
                </H>
                <ul className={styles.list}>
                  {section.terms.map((term) => (
                    <li key={term.databaseId} className={styles.item}>
                      <Link to={term.uri} className={styles.link}>
                        {term.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </div>
      </Section>
    </ModuleWrapper>
  );
}
