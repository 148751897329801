import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./HelpMenu.module.css";
import Menu from "./Menu";

HelpMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function HelpMenu({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <Menu
      menuName="helpMenu"
      styles={styles}
      className={clsx(styles.component, className)}
      {...restProps}
    />
  );
}
