import { Link } from "@whitespace/components";
import clsx from "clsx";
import { kebabCase, startCase, camelCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./EngagementTeaser.module.css";

EngagementTeaser.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  title: PropTypes.string,
  modEngagementOptions: PropTypes.any,
  usedInPosts: PropTypes.any,
};

export default function EngagementTeaser({
  styles = defaultStyles,
  className,
  title,
  modEngagementOptions: { tint, description },
  usedInPosts,
  ...restProps
}) {
  const uri = usedInPosts[0]?.uri;
  if (!uri) return null;
  const url = uri + "#" + kebabCase(title);
  return (
    <Link
      className={clsx(
        styles.component,
        styles[`tint${startCase(camelCase(tint))}`],
        className
      )}
      to={url}
      {...restProps}
    >
      <span className={styles.title}>{title}</span>
      {description && <span className={styles.description}>{description}</span>}
    </Link>
  );
}
