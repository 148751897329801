import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

export const HeaderContext = createContext({});

export const Provider = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(true);

  return (
    <HeaderContext.Provider value={{ showMobileMenu, setShowMobileMenu }}>
      {children}
    </HeaderContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.any,
};
