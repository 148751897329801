import { Link, Icon } from "@whitespace/components";
import FooterMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/FooterMenu";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import * as layout from "gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import PropTypes from "prop-types";
import React from "react";

import Logo from "../../../components/Logo";
import useFooterSettings from "../../../hooks/footer-settings";

import * as defaultStyles from "./Footer.module.css";

Footer.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Footer({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { contactInformation } = useFooterSettings();
  const socialMediaMenu = useMenu("SOCIAL_MEDIA_LINKS");

  return (
    <footer className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.index, styles.row)}>
        <div className={styles.column}>
          <FooterMenu menuName="headerTabsMenu" />
        </div>
        <div className={styles.column}>
          <FooterMenu menuName="helpMenu" />
        </div>
        {(contactInformation.postalAddress ||
          contactInformation.email ||
          contactInformation.phone) && (
          <div className={styles.column}>
            <dl>
              {contactInformation.postalAddress && (
                <>
                  <dt>Postadress</dt>
                  <dd>{contactInformation.postalAddress}</dd>
                </>
              )}
              {contactInformation.email && (
                <>
                  <dt>E-post</dt>
                  <dd>
                    <Link to={"mailto:" + contactInformation.email}>
                      {contactInformation.email}
                    </Link>
                  </dd>
                </>
              )}
              {contactInformation.phone && (
                <>
                  <dt>Telefon</dt>
                  <dd>{contactInformation.phone}</dd>
                </>
              )}
            </dl>
          </div>
        )}
        <div className={clsx(styles.logo, styles.column)}>
          <Logo />
        </div>
      </div>
      <div className={styles.row}>
        {socialMediaMenu && socialMediaMenu.items && (
          <ul className={clsx(styles.column, styles.socialMediaList)}>
            {socialMediaMenu.items.map((item, index) => (
              <li key={index}>
                <Link to={item.url}>
                  <Icon
                    className={clsx(styles.icon, styles[item.extra.icon])}
                    name={item.extra.icon}
                  />
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className={styles.row}>
        <div className={clsx(styles.column, styles.footerText)}>
          © {new Date().getFullYear()} Vänsterpartiet • Vänsterpartiet •{" "}
          {contactInformation.postalAddress} • Telefon:{" "}
          {contactInformation.phone} • E-post: {contactInformation.email}
        </div>
      </div>
    </footer>
  );
}
