import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import EngagementTeaser from "../../../../components/EngagementTeaser";

import * as defaultStyles from "./BillboardModule.module.css";

BillboardModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function BillboardModule({
  styles = defaultStyles,
  className,
  title,
  module: { modBillboardOptions: { text, teasers } = {} },
  ...restProps
}) {
  return (
    <ModuleWrapper
      as={"div"}
      title={title}
      styles={styles}
      className={clsx(className)}
      {...restProps}
    >
      {text && (
        <div className={styles.preamble}>
          <HTML>{text}</HTML>
        </div>
      )}
      <ul className={styles.list}>
        {teasers.map(({ teaser }, index) => {
          return (
            <li key={index} className={styles.item}>
              {teaser.modEngagementOptions && <EngagementTeaser {...teaser} />}
            </li>
          );
        })}
      </ul>
    </ModuleWrapper>
  );
}
