import { Icon, Link } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import { snakeCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./Menu.module.css";

Menu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuName: PropTypes.string,
  innerRef: PropTypes.func,
};

export default function Menu({
  styles = defaultStyles,
  className,
  menuName = "mainMenu",
  innerRef = null,
  ...restProps
}) {
  const menu = useMenu(snakeCase(menuName).toUpperCase());
  const items = menu?.items;
  if (!items?.length) {
    return null;
  }

  const filteredItems = items?.filter((el) => el.label !== "Cookies");
  return (
    <nav className={clsx(styles.component, className)} {...restProps}>
      {
        <ul className={styles.list} ref={innerRef}>
          {filteredItems.map((item, index) => {
            return (
              <li key={index} className={styles.item}>
                <Link className={styles.link} to={item.url}>
                  {item?.extra?.icon && <Icon name={item.extra.icon} />}
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
      }
    </nav>
  );
}
