import { useComponentSize } from "@whitespace/gatsby-hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as defaultStyles from "./HeaderUtilsMenu.module.css";
import Menu from "./Menu";

HeaderUtilsMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function HeaderUtilsMenu({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <Menu
      menuName="utilsMenu"
      styles={styles}
      className={clsx(styles.component, className)}
      {...restProps}
    />
  );
}
