// extracted by mini-css-extract-plugin
export var column = "Footer-module--column--Loyq+";
export var component = "Footer-module--component--cqrgy";
export var footerText = "Footer-module--footerText--fshVA";
export var icon = "Footer-module--icon--3Wngr";
export var index = "Footer-module--index--2jZoj";
export var logo = "Footer-module--logo--Jrwy6";
export var row = "Footer-module--row--+VZas";
export var socialMediaLinks = "Footer-module--socialMediaLinks--FvEPC";
export var socialMediaList = "Footer-module--socialMediaList--NPBgI";
export var youtube = "Footer-module--youtube--jkU+j";