import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Icon, Link } from "@whitespace/components";
import clsx from "clsx";
import { kebabCase, startCase, camelCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./EngagementModule.module.css";

EngagementModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function EngagementModule({
  styles = defaultStyles,
  className,
  title,
  module: { modEngagementOptions: { tint, description, links, icon } = {} },
  ...restProps
}) {
  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <div
          className={clsx(
            styles.circle,
            styles[`tint${startCase(camelCase(tint))}`]
          )}
        >
          <Icon
            name={icon}
            size="3.25rem"
            className={clsx(
              styles.icon,
              styles[`tint${startCase(camelCase(tint))}`]
            )}
          />
        </div>
        <ModuleWrapper
          id={kebabCase(title)}
          as={"div"}
          title={title}
          {...restProps}
          styles={defaultStyles}
        >
          {description}
        </ModuleWrapper>
      </div>
      <ul className={styles.links}>
        {links?.map(({ link }) => {
          return (
            <Link
              key={link?.url}
              to={link?.url}
              className={clsx(
                styles.link,
                styles[`tint${startCase(camelCase(tint))}`],
                className
              )}
            >
              {link?.title}
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
