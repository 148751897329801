import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import TextContent from "../../../../@whitespace/gatsby-theme-wordpress-basic/components/TextContent";

import * as defaultStyles from "./OctanyModule.module.css";

OctanyModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function OctanyModule({
  styles = defaultStyles,
  className,
  title,
  module: {
    modOctanyOptions: { text, accountId = 1504, productId = 5196 } = {},
  },
  ...restProps
}) {
  const locale = "sv";

  return (
    <>
      <Helmet>
        <script
          src="https://app.octany.com/js/checkout.js?v=5"
          type="text/javascript"
        />
      </Helmet>

      <ModuleWrapper
        as={"div"}
        title={title}
        styles={styles}
        className={clsx(styles.component, className)}
        {...restProps}
      >
        {text && (
          <TextContent className={clsx(styles.text)}>
            <HTML>{text}</HTML>
          </TextContent>
        )}
        <div
          className={clsx(styles.wrapper)}
          dangerouslySetInnerHTML={{
            __html: `<octany-widget
              version="2"
              url="https://app.octany.com"
              account-id="${accountId}"
              product-id="${productId}"
              locale="${locale}"
              type="iframe"
            />`,
          }}
        />
      </ModuleWrapper>
    </>
  );
}
