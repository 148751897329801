import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/SiteLayout.module.css";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import PageBreadcrumbs from "./PageBreadcrumbs";

SiteLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SiteLayout({
  children,
  className,
  styles = defaultStyles,
}) {
  const { contentNode } = usePageContext();
  const { position, active, strings } = useCookieConsentSettings();

  return (
    <div className={clsx(styles.component, className)}>
      <CookieConsent
        position={position}
        cookieConsentSettings={strings}
        active={active}
      />
      <Header />
      <main className={styles.main} id="main">
        {!contentNode || (!contentNode?.isFrontPage && <PageBreadcrumbs />)}
        {children}
      </main>
      <Footer />
    </div>
  );
}
